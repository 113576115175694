import { useEffect, useState } from "react";
import "./_loading.scss";
import svg from "../../assets/icons/c2.svg";
import { useSelector } from "react-redux";

function LoadingOverlay() {
  const [isActive, setIsActive] = useState(false);
  const { isOpen } = useSelector((state) => state.modals.overlay);

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);
  return (
    <div>
      <div className={`loading-overlay ${isActive ? "is-active" : ""}`}>
        <img src={svg} className="fas fa-spinner fa-3x fa-spin"></img>
        <button onClick={() => setIsActive(false)}>cancel</button>
      </div>

      {/* <button id="load-button" onClick={handleLoad}>
        Load!
      </button> */}
    </div>
  );
}

export default LoadingOverlay;
