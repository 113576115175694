import { Input } from "antd";

function InputText(props) {
  const { field, formik, value } = props;
  const InputComponent =
    field?.type === "password"
      ? Input.Password
      : field.type === "textarea"
      ? Input.TextArea
      : Input;
  return (
    <span className="s_input">
      {field?.label && <label htmlFor={field?.name}>{field?.label}</label>}
      <InputComponent
        id={field?.name}
        name={field?.name}
        type={field?.type}
        className="input_antd"
        placeholder={field?.placeholder}
        value={value || formik?.values[field?.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        status={
          formik.errors[field?.name] && formik.touched[field?.name]
            ? "error"
            : null
        }
        autoComplete="new-password"
        prefix={field.prefix && <img src={field?.prefix}></img>}
        rows={field?.rows}
        autoSize={{
          minRows: field?.rows,
          maxRows: field?.rows,
        }}
      />
      {formik.errors[field.name] && formik.touched[field.name] && (
        <p className="error_txt">{formik.errors[field.name]}</p>
      )}
    </span>
  );
}

export default InputText;
