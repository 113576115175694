import { BrowserRouter, Routes, Route } from "react-router-dom";
import ModalsProvider from "./components/ModalsProvider";
import MainLayout from "./layouts/MainLayout";
import Progress from "./components/Progress/Progress";
import NotFoundView from "./components/NotFound";

import { lazy } from "react";
import { Suspense } from "react";
import Spinner from "./components/Spinner/Spinner";

const RoutesProvider = () => {
  const HomeView = lazy(() => import("./views/Home"));
  const AboutView = lazy(() => import("./views/About"));
  const PhoneVerif = lazy(() =>
    import("./views/Register/components/PhoneVerification/PhoneVerification")
  );
  const ResetPwd = lazy(() =>
    import("./views/Register/components/ResetPassword/ResetPassword")
  );
  const Profile = lazy(() => import("./views/Profile/Profile"));
  const CartPage = lazy(() => import("./views/CartPage/CartPage"));

  const FillInfoPage = lazy(() =>
    import("./views/Register/components/FillInfo/FillInfo")
  );

  const SharePage = lazy(() => import("./views/ShareBatchPage/Share"));

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Routes>
          {/* Users Routes */}
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomeView />} />
            <Route path="about" element={<AboutView />} />
            <Route path="cart" element={<CartPage />} />

            <Route path="phone-verification" element={<PhoneVerif />} />
            <Route path="reset-password" element={<ResetPwd />} />
            <Route path="profile" element={<Profile />} />
            <Route path="register/information" element={<FillInfoPage />} />
            <Route path="batches/:id" element={<SharePage />} />
          </Route>
          {/* Uncreated Routes */}
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Suspense>
      <ModalsProvider />
    </BrowserRouter>
  );
};

export default RoutesProvider;
