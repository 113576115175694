import fb from "../../assets/icons/fb.svg";
import ggplay from "../../assets/icons/ggplay.svg";
import tw from "../../assets/icons/tw.svg";
import appstore from "../../assets/icons/appstore.svg";
import insta from "../../assets/icons/insta.svg";
import linked from "../../assets/icons/linked.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openModal } from "../../data/slices/modals";
import i18next from "i18next";
import cookies from "js-cookie";
import logoen from "../../assets/images/footeren.svg";
import logoar from "../../assets/images/footerar.svg";

function Footer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <div className="footer">
      <div className="left">
        {/* <p className="title">{t("footer.text1")}</p> */}
        {/* <Logo img={logoImg} /> */}
        <img src={currentLanguageCode === "en" ? logoen : logoar} />
        <p className="content">{t("footer.text2")}</p>
      </div>
      <div className="right">
        <div className="icons">
          <a href="">
            <img src={linked} alt="" />
          </a>
          <a href="">
            <img src={fb} alt="" />
          </a>
          <a href="">
            <img src={tw} alt="" />
          </a>
          <a href="">
            <img src={insta} alt="" />
          </a>

          <a href="">
            <img src={ggplay} alt="" />
          </a>
          <a href="">
            <img src={appstore} alt="" />
          </a>
        </div>
        <div className="items">
          <p
            className="ar"
            onClick={() => {
              if (currentLanguageCode === "en") {
                i18next.changeLanguage("ar");
              } else {
                i18next.changeLanguage("en");
              }
            }}
          >
            {t("language")}
          </p>
          <p
            className="signup"
            onClick={() => dispatch(openModal("register-modal"))}
          >
            {t("header.register")}
          </p>
          <NavLink className="nav_link" to="/about">
            {t("header.about_as")}
          </NavLink>
        </div>
        <div className="last_privacy">
          <p className="text1">{t("footer.text3")}</p>
          <p className="text2">{t("footer.text4")}</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
