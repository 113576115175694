function cleanObject(obj) {
  Object.keys(obj).forEach(function (key) {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === "" ||
      obj[key]?.length === 0
    ) {
      delete obj[key];
    }
  });
  return obj;
}

export { cleanObject };
