import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  totalQuantity: 0,
  isOpenCart: false,
};
//totalQuantity is the number of items added to the cart
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const newItem = action.payload;
      //id item already exist
      const isExistItem = state.items.find(
        (item) => item.batch_id === newItem.batch_id
      );
      state.totalQuantity++;

      if (!isExistItem) {
        state.items.push({
          ...newItem,
          quantity: +newItem?.cart_quantity,
          totalPrice: +newItem.total_sale_amount,
          totalSize: newItem.weight,
        });
      } else {
        isExistItem.quantity++;
        isExistItem.totalPrice =
          +isExistItem.totalPrice + +newItem.total_sale_amount;
        isExistItem.totalSize = +isExistItem.totalSize + +newItem.weight;
      }
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.batch_id === id);
      state.totalQuantity--;

      if (existingItem.quantity === 1) {
        //   // state.items = state.items.filter((item) => item.batch_id !== id);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice =
          existingItem.totalPrice - existingItem.total_sale_amount;
        existingItem.totalSize = +existingItem.totalSize - +existingItem.weight;
      }
    },
    initItemToCart(state, action) {
      const newItems = action.payload;
      let arr = [];
      for (let i = 0; i < newItems.length; i++) {
        let obj = {
          ...newItems[i],
          quantity: +newItems[i]?.cart_quantity,
          totalPrice: +newItems[i].total_sale_amount,
          totalSize: newItems[i].weight,
        };
        arr.push(obj);
      }
      state.items = arr;
    },
  },
});

export const { addItemToCart, removeItemFromCart, initItemToCart } =
  cartSlice.actions;

// export const reducer = cartSlice.reducer;

export default cartSlice.reducer;
