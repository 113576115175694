import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  products: [],
  batch: null,
  error: null,
  loading: "idle",
  rangeCost: [],
  share_loading: "idle",
};

export const getProducts = createAsyncThunk(
  "api/products",
  async ({ values, lang }) => {
    let data;
    try {
      const response = await axiosInstance.post(
        `/login/get_batches?lang=${lang}`,
        {
          ...values,
        }
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

//get by id

export const getBatchById = createAsyncThunk(
  "api/byId",
  async ({ id, lang }) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/login/get_batch_detail?id=${id}&lang=${lang}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getProducts.fulfilled]: (state, action) => {
      const { batch, filter } = action.payload;

      // console.log(batch);
      if (batch) {
        state.products = batch;
      } else {
        state.products = [];
      }
      if (filter && state.rangeCost.length === 0) {
        state.rangeCost = [+filter.min_cost_per_kg, +filter.max_cost_per_kg];
      }
      state.loading = "success";
    },
    [getProducts.rejected]: (state, action) => {
      state.error = action.error.message;
    },

    // get ny id
    [getBatchById.pending]: (state) => {
      state.error = null;
      state.share_loading = "loading";
    },
    [getBatchById.fulfilled]: (state, action) => {
      state.batch = action.payload;
      state.share_loading = "success";
    },
    [getBatchById.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const {} = productsSlice.actions;

export default productsSlice.reducer;
