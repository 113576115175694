import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { set_is_available } from "../../../../data/slices/filterBatches";
import handleName from "../../../../helpers/handleName";
import CollapseBtn from "../Collapse/Collapse";
import cookies from "js-cookie";

function AvFilterItem({ items, title, selectedItems, type }) {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const [isOpen, setIsOpen] = useState(true);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="product_type_filter">
      <div className="nav_filter">
        <p className="title">{t(`filter_nav.${title}`)}</p>
        <div className="collapse_items">
          {Array.isArray(selectedItems) ? (
            <p
              className={"btn_select_all clicked_all"}
              onClick={() => {
                dispatch(set_is_available(null));
              }}
            >
              {t("filter_nav.unselect_all")}
            </p>
          ) : (
            <p
              className={"btn_select_all"}
              onClick={() => {
                dispatch(set_is_available([]));
              }}
            >
              {t("filter_nav.Select_all")}
            </p>
          )}
          <span className="point"></span>
          <CollapseBtn
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            text={isOpen ? t("filter_nav.show_less") : t("filter_nav.show_all")}
          />
        </div>
      </div>
      {isOpen && (
        <div className="content">
          <div className="content_collapse">
            <button
              className={
                selectedItems === "no" || Array.isArray(selectedItems)
                  ? "btn_collapse clicked"
                  : "btn_collapse"
              }
              onClick={() => dispatch(set_is_available("no"))}
            >
              {handleName(
                items[0]?.product_type_name_ar,
                items[0]?.product_type_name,
                currentLanguageCode
              ) || items[0]?.label}
            </button>
            <button
              className={
                selectedItems === "yes" || Array.isArray(selectedItems)
                  ? "btn_collapse clicked"
                  : "btn_collapse"
              }
              onClick={() => dispatch(set_is_available("yes"))}
            >
              {handleName(
                items[1]?.product_type_name_ar,
                items[1]?.product_type_name,
                currentLanguageCode
              ) || items[1]?.label}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AvFilterItem;
