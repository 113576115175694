import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { store } from "./data";
import { Provider } from "react-redux";
import "./main.scss";
import ModalsProvider from "./components/ModalsProvider";
import AuthProvider from "./components/AuthProvider";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import App from "./App";
import LoadingOverlay from "./components/overlayLoading/Loading";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar", "fr"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <App />
        <LoadingOverlay />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);
