import "./_Logo.scss";
import cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import arLogo from "../../assets/images/logoarabic.svg";
import enLogo from "../../assets/images/logoenglish.svg";

function Logo({ img }) {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <NavLink style={{ cursor: "pointer" }} to="/">
      <img
        className="img_logo"
        src={currentLanguageCode === "en" ? enLogo : arLogo}
        alt=""
      />
    </NavLink>
  );
}

export default Logo;
