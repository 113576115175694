import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { removeFromCart as Remove } from "../../../../data/slices/cart";
import { message } from "antd";
import { getCount, getProductsCart } from "../../../../data/slices/cart";
import { getCookieValue } from "../../../../helpers/setDeviceData";

function DeleteModal({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const uid = JSON.parse(localStorage.getItem("uid"));

  const token = localStorage.getItem("haraj_token");
  const device_data = getCookieValue("device_data");

  const removeFromCart = () => {
    dispatch(Remove({ cart_id: data?.cart_id }))
      .unwrap()
      .then((res) => {
        if (res.error === 1) {
          message.error(res.message || t("messages.want_wrong"));
        } else {
          message.success(t("messages.remove_from_cart_success"));
          if (token) {
            dispatch(getCount({ key: "user", value: uid }));
            dispatch(getProductsCart({ key: "user", value: uid }));
          } else {
            dispatch(
              getCount({
                key: "device_data",
                value: device_data,
              })
            );
            dispatch(
              getProductsCart({
                key: "device_data",
                value: device_data,
              })
            );
          }
          handleClose(id);
        }
      })
      .catch((err) => {
        console.log("err", err);
        message.error(t("messages.want_wrong"));
      });
  };

  return (
    <div className="delete_modal">
      <Modal
        wrapClassName="delete_modal"
        open={open}
        onCancel={() => {
          handleClose(id);
        }}
        width="280px"
        footer={false}
      >
        <p className="title_delete">{t("cart.Delete_Order")}</p>
        <p className="subtitle_delete">
          {t("cart.Are_you_sure_to_delete_order")}
        </p>
        <p className="question">{data?.name} ?</p>
        <div
          className="actions"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button
            className="cancel_delete"
            onClick={() => {
              handleClose(id);
            }}
          >
            {t("cart.Cancel")}
          </button>
          <button className="cancel_delete d_btn" onClick={removeFromCart}>
            {t("cart.Confirm")}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteModal;
