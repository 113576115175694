import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./Carousel.scss";
import VideoComponent from "../../Video/Video";
import { FileType } from "../../../helpers/filterImages";
import share from "../../../assets/icons/share.svg";
import { message } from "antd";
import { useTranslation } from "react-i18next";
// import { handleCopyUrl } from "../../../helpers/copyToShare";

const Carousel = ({ images, isAvailable, batch_id }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const handleCopyUrl = (batch_id) => {
    const sharedUrl = window.location.origin + `/batches/${batch_id}`; // Modify this based on your URL structure
    navigator.clipboard
      .writeText(sharedUrl)
      .then(() => {
        console.log("URL copied successfully", sharedUrl);
        message.success(t("messages.share"));
      })
      .catch((error) => {
        console.error("Failed to copy URL:", error);
        message.error("Failed to copy URL");
      });
  };

  return (
    <div className="carousel">
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          <div className="share" onClick={() => handleCopyUrl(batch_id)}>
            <img src={share} alt="" />
          </div>
          <div
            className={isAvailable === "available" ? "Available" : "soldout"}
          >
            {isAvailable === "available"
              ? t("filter_nav.Available")
              : t("filter_nav.soldout")}
          </div>
          {images?.map((el, index) => (
            <div key={index} className="keen-slider__slide number-slide1">
              {/* <img src={el} className="image" alt="err_product" /> */}
              {FileType(el) === "mp4" ? (
                <VideoComponent video={el} />
              ) : (
                <img src={el} className="image" alt="err_product" />
              )}
            </div>
          ))}
        </div>
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(
              instanceRef?.current?.track.details?.slides?.length
            ).keys(),
          ]?.map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
