import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../data/slices/modals";
import DeleteModal from "../../views/CartPage/components/DeleteModal/DeleteModal";
import Login from "../../views/Login/Login";
import Register from "../../views/Register/Register";
import { ModalExample } from "../Modals";
import ForgotPwdModal from "../Modals/ForgotPwdModal/ForgotPwdModal";
import ProductModal from "../Modals/ProductModal/ProductModal";

const ModalsProvider = (props) => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return (
    <>
      <ModalExample
        id="modal-example"
        open={modalState("modal-example", "open")}
        data={modalState("modal-example", "data")}
        handleClose={handleClose}
      />
      <ProductModal
        id="product-modal"
        open={modalState("product-modal", "open")}
        data={modalState("product-modal", "data")}
        handleClose={handleClose}
      />
      <Login
        id="login-modal"
        open={modalState("login-modal", "open")}
        data={modalState("login-modal", "data")}
        handleClose={handleClose}
      />
      <Register
        id="register-modal"
        open={modalState("register-modal", "open")}
        data={modalState("register-modal", "data")}
        handleClose={handleClose}
      />
      <DeleteModal
        id="delete-modal"
        open={modalState("delete-modal", "open")}
        data={modalState("delete-modal", "data")}
        handleClose={handleClose}
      />
      <ForgotPwdModal
        id="forgot-modal"
        open={modalState("forgot-modal", "open")}
        data={modalState("forgot-modal", "data")}
        handleClose={handleClose}
      />
    </>
  );
};

export default ModalsProvider;
