import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "../../utils/publicAxios";
import axiosInstance from "../../utils/axios";
import cookies from "js-cookie";

const lang = cookies.get("i18next") || "en";

const initialState = {
  cart_products: [],
  count: 0,
  error: null,
  loading: "idle",
  add_loading: "idle",
  shipping_cost: 0,
  current_city_id: 6,
  shipping_Countries: [],
  shipping_charges: [],
};

export const getProductsCart = createAsyncThunk(
  "api/products_cart",
  async (query) => {
    const { key, value, lang } = query;
    let data;
    try {
      const response = await axiosInstance.get(
        `/login/get_cart?${key}=${value}&lang=${lang}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getCount = createAsyncThunk("api/count", async (query) => {
  const { key, value } = query;
  let data;
  try {
    const response = await axiosInstance.get(
      `/login/get_cart_count?${key}=${value}`
    );
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const addProductToCart = createAsyncThunk(
  "api/add_products_cart",
  async (values) => {
    let data;
    try {
      const response = await publicAxios.post(`/login/add_cart_batch`, {
        ...values,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
//remove from cart
export const removeFromCart = createAsyncThunk(
  "api/remove_from_cart",
  async (values) => {
    let data;
    try {
      const response = await axiosInstance.post(`/login/remove_from_cart`, {
        ...values,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
//get shipping cost
// export const getShippingCost = createAsyncThunk(
//   "api/ship_cost",
//   async (query) => {
//     const { city_id, transfer_id } = query;
//     let data;
//     try {
//       const response = await axiosInstance.get(
//         `/login/get_shipping_charges?city_id=${city_id}&transfer_id=${transfer_id}`
//       );
//       data = await response.data;
//       if (response.status === 200) {
//         return data;
//       }
//       throw new Error(response.statusText);
//     } catch (err) {
//       // const error = { message: err.payload.errors[0] };
//       const error = err;
//       return Promise.reject(error.message ? error.message : data?.message);
//     }
//   }
// );

export const getShippingCharges = createAsyncThunk(
  "api/ship_charges",
  async (query) => {
    const { country_id, lang } = query;
    let data;
    try {
      const response = await axiosInstance.get(
        `/user/get_shipping_charges?country_id=${country_id}&lang=${lang}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

//get shipping countries
export const getShippingCountries = createAsyncThunk(
  "api/ship_countries",
  async (query) => {
    let { lang } = query;
    let data;
    try {
      const response = await axiosInstance.get(
        `/user/get_shipping_country?lang=${lang}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

//reserve cart
export const reserveCart = createAsyncThunk(
  "api/reserve_cart",
  async (query) => {
    let data;
    let { values, lang } = query;
    try {
      const response = await axiosInstance.post(
        `/user/reserve_cart?lang=${lang}`,
        {
          ...values,
        }
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

//make invoice
export const makeInvoice = createAsyncThunk(
  "api/make-invoice",
  async (values) => {
    let data;
    try {
      const response = await axiosInstance.post(
        `/user/make_invoice?lang=${lang}`,
        {
          ...values,
        }
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const productCartSlice = createSlice({
  name: "productCart",
  initialState,
  reducers: {
    setCurrentCity(state, action) {
      state.current_city_id = action.payload;
    },
    setShippingCost(state, action) {
      state.shipping_cost = 0;
    },
  },
  extraReducers: {
    // get products of cart
    [getProductsCart.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getProductsCart.fulfilled]: (state, action) => {
      const { batch } = action.payload;
      if (batch) {
        state.cart_products = batch;
      } else {
        state.cart_products = [];
      }
      state.loading = "idle";
    },
    [getProductsCart.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //add item to cart
    [addProductToCart.pending]: (state) => {
      state.error = null;
      state.add_loading = "loading";
    },
    [addProductToCart.fulfilled]: (state, action) => {
      state.add_loading = "success";
    },
    [addProductToCart.rejected]: (state, action) => {
      state.error = action.error.message;
      state.add_loading = "error";
    },
    //add count cart
    [getCount.pending]: (state) => {
      state.error = null;
      state.add_loading = "loading";
    },
    [getCount.fulfilled]: (state, action) => {
      state.add_loading = "success";
      const { count } = action.payload;
      state.count = count;
    },
    [getCount.rejected]: (state, action) => {
      state.error = action.error.message;
      state.add_loading = "error";
    },
    //get shipping cost
    [getShippingCharges.pending]: (state) => {
      state.error = null;
    },
    [getShippingCharges.fulfilled]: (state, action) => {
      const { shipping } = action.payload;
      state.shipping_charges = shipping;
    },
    [getShippingCharges.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    //get shipping countries
    [getShippingCountries.pending]: (state) => {
      state.error = null;
    },
    [getShippingCountries.fulfilled]: (state, action) => {
      const { country } = action.payload;
      state.shipping_Countries = country;
    },
    [getShippingCountries.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { setCurrentCity, setShippingCost } = productCartSlice.actions;

export default productCartSlice.reducer;
