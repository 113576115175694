import { configureStore } from "@reduxjs/toolkit";
import exampleReducer from "./slices/exampleSlice";
import authReducer from "./slices/authSlice";
import { reducer as modalsReducer } from "./slices/modals";
import settingsReducer from "./slices/settingsSlice";
import cartReducer from "./slices/cartSlice";
import categoriesReducer from "./slices/productCategories";
import filterReducer from "./slices/filterBatches";
import productTypesReducer from "./slices/productTypes";
import productsReducer from "./slices/products";
import countryReducer from "./slices/country";
import productCartReducer from "./slices/cart";
import userReducer from "./slices/user";

export const store = configureStore({
  reducer: {
    example: exampleReducer,
    auth: authReducer,
    modals: modalsReducer,
    settings: settingsReducer,
    cart: cartReducer,
    categories: categoriesReducer,
    filter: filterReducer,
    types: productTypesReducer,
    products: productsReducer,
    countries: countryReducer,
    productCart: productCartReducer,
    user: userReducer,
  },
});
