import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import RoutesProvider from "./routes";
import { getCookieValue, setDeviceData } from "./helpers/setDeviceData";
import { useDispatch } from "react-redux";
import "antd/dist/antd.css";
// import "postcss-gap-properties";

function App() {
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);
  const device_data = getCookieValue("device_data");
  useEffect(() => {
    if (!device_data) {
      setDeviceData();
    }
  }, []);

  return (
    <>
      <RoutesProvider />
    </>
  );
}

export default App;
