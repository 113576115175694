import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import PhoneNumber from "../../../components/InputGenerator/PhoneInput/PhoneInput";
import { openModal } from "../../../data/slices/modals";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputSelect from "../../../components/InputGenerator/InputSelect/InputSelect";
import { forgotPwd, register } from "../../../data/slices/authSlice";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const ForgotPwdModal = ({ id, open, handleClose, data, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  let field = {
    name: "phone",
    type: "number",
    placeholder: t("profile.phone_number"),
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      country_code: "+966",
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .min(2, t("validations.too_short"))
        .max(70, "Too Long!")
        .required(t("validations.required")),
    }),
    onSubmit: (values) => {
      dispatch(forgotPwd(values))
        .unwrap()
        .then((res) => {
          console.log(res);
          if (res.error === 1) {
            message.error(res.message || t("messages.want_wrong"));
          } else {
            // message.success(res.message);
            handleClose(id);
            navigate("phone-verification", {
              state: { user: res.user, type: "forget_pwd" },
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          message.error(t("messages.want_wrong"));
        });
    },
  });
  return (
    <div className="login_modal">
      <form onSubmit={formik.handleSubmit}>
        <Modal
          wrapClassName="modal_login_antd"
          open={open}
          onCancel={() => {
            handleClose(id);
          }}
          width="404px"
          footer={false}
        >
          <p className="title">{t("register.title")}</p>
          <p className="text1">{t("register.text1")}</p>
          <div className="inputs">
            <PhoneNumber
              type="number"
              placeholder={field.placeholder}
              label="Phone Number"
              name="phone"
              formik={formik}
            />
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {loading === "idle" ? (
                t("forgot_pwd.btn_verif")
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
          </div>
        </Modal>
      </form>
    </div>
  );
};

export default ForgotPwdModal;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "#ffff",
    }}
    spin
  />
);
