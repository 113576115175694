import { useRef } from "react";
import "./Video.scss";

function VideoComponent({ video }) {
  const videoRef = useRef(null);

  return (
    <div className="video-custom">
      <video ref={videoRef} width="100%" controls muted loop>
        <source src={video} type="video/mp4" />
        <source src={video} type="video/ogg" />
        <source src={video} type="video/webm" />
        <source src={video} type="video/avi" />
      </video>
    </div>
  );
}

export default VideoComponent;
