import { useState } from "react";
import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { set_cost_range } from "../../data/slices/filterBatches";
import { useEffect } from "react";

const Demo = () => {
  const { rangeCost } = useSelector((state) => state.products);

  const [values, setValues] = useState([0, 100]);
  const [cost, setCost] = useState([0, 100]);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  function setRange(v) {
    dispatch(set_cost_range(v));
  }

  useEffect(() => {
    if (rangeCost.length > 0) {
      setCost(rangeCost);
      setValues(rangeCost);
    }
  }, [rangeCost]);

  return (
    <div>
      <Slider
        reverse={currentLanguageCode === "ar"}
        range
        defaultValue={values}
        max={cost[1]}
        min={cost[0]}
        onAfterChange={(v) => {
          setRange(v);
        }}
        onChange={(v) => {
          setValues(v);
        }}
      />
      <div className="values_slider">
        <p>
          {values[0]} {t("filter_nav.r_s")}
        </p>
        <p>
          {values[1]} {t("filter_nav.r_s")}
        </p>
      </div>
    </div>
  );
};

export default Demo;
