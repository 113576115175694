import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import publicAxios from "../../utils/publicAxios";

const initialState = {
  product_types: [],
  markets: [],

  error: null,
  loading: "idle",
};

export const getProductTypes = createAsyncThunk("api/types", async (query) => {
  let data;
  try {
    const response = await axiosInstance.get(`/login/get_product_type${query}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getMarkets = createAsyncThunk("api/markets", async (values) => {
  let data;
  try {
    const response = await axiosInstance.get(`/login/get_market_source`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});
export const typesSlice = createSlice({
  name: "productTypes",
  initialState,
  reducers: {},
  extraReducers: {
    [getProductTypes.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getProductTypes.fulfilled]: (state, action) => {
      const { product_type } = action.payload;
      state.product_types = product_type;
      state.loading = "idle";
    },
    [getProductTypes.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getMarkets.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getMarkets.fulfilled]: (state, action) => {
      const { market_source } = action.payload;
      state.markets = market_source;
      state.loading = "idle";
    },
    [getMarkets.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const {} = typesSlice.actions;

export default typesSlice.reducer;
