function setDeviceData() {
  const timestamp = Math.floor(Date.now() / 1000); // convert current time to UNIX timestamp
  const randomString = Math.random().toString(36).substring(2, 8); // generate a random string of 6 characters
  const deviceData = `${timestamp}${randomString}`; // concatenate timestamp and random string
  document.cookie = `device_data=${deviceData}`; // set cookie with device_data value
  return deviceData;
}

function getCookieValue(cookieName) {
  // Split the document.cookie string into individual cookies using '; ' as the delimiter
  const cookies = document.cookie.split("; ");

  // Loop through each cookie and check if it has the desired name
  for (let i = 0; i < cookies.length; i++) {
    const [name, value] = cookies[i].split("=");
    if (name === cookieName) {
      // If the cookie with the desired name is found, return its value
      return decodeURIComponent(value); // decodeURIComponent() is used to handle URL encoding of cookie value
    }
  }

  // If the cookie with the desired name is not found, return null
  return null;
}

export { setDeviceData, getCookieValue };
