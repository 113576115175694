import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import closeModal from "../../../assets/icons/close.svg";
import AddProduct from "../../../assets/icons/v3.svg";
import removeProduct from "../../../assets/icons/v1.svg";
import { useTranslation } from "react-i18next";
import Carousel from "./Carousel";
import Collapse from "./Collapse";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addProductToCart, getProductsCart } from "../../../data/slices/cart";
import { message } from "antd";
import { getCookieValue } from "../../../helpers/setDeviceData";
import { getCount } from "../../../data/slices/cart";
import WhatsAppSender from "../../WhatsappSender/SendMessage";
import cookies from "js-cookie";

const ProductModal = ({ id, open, handleClose, data, ...rest }) => {
  const [values, setValues] = useState();
  const [countDetails, setCountDetails] = useState();
  const currentLanguageCode = cookies.get("i18next") || "en";

  const token = localStorage.getItem("haraj_token");
  const { user } = useSelector((state) => state.auth);
  const uid = JSON.parse(localStorage.getItem("uid"));

  const device_data = getCookieValue("device_data");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addToCart = () => {
    dispatch(addProductToCart(values))
      .unwrap()
      .then((res) => {
        if (res.error === 0) {
          // message.success(res.message);
          message.success(t("messages.added_to_cart"));
          handleClose(id);
          if (!token) {
            dispatch(
              getProductsCart({
                key: "device_data",
                value: device_data,
              })
            );
            dispatch(
              getCount({
                key: "device_data",
                value: device_data,
              })
            );
          } else {
            dispatch(
              getProductsCart({
                key: "user",
                value: uid,
              })
            );
            dispatch(getCount({ key: "user", value: uid }));
          }
        } else {
          handleClose(id);
          message.error(res.message || t("messages.want_wrong"));
        }
      })
      .catch((err) => {
        message.error(t("messages.want_wrong"));
      });
  };
  // init the object of the cart
  useEffect(() => {
    if (token) {
      setValues({
        quantity: "1",
        weight: data?.product?.weight,
        batch_id: data?.product?.batch_id,
        transfer_id: data?.product?.transfer_id,
        user: uid || null,
      });
    } else {
      setValues({
        quantity: "1",
        weight: data?.product?.weight,
        batch_id: data?.product?.batch_id,
        transfer_id: data?.product?.transfer_id,
        device_data: device_data,
      });
    }
    setCountDetails({
      price: data?.product?.auction_price,
      totalPrice: data?.product?.total_sale_amount,
    });
  }, [data?.product, token]);

  const setSize = (action) => {
    const item = { ...values };
    const counts = { ...countDetails };

    if (action === "add") {
      if (+data.product.available_quantity > item.quantity) {
        item.quantity = +item.quantity + 1;
      }
    } else if (action === "remove" && item?.quantity > 1) {
      item.quantity = +item.quantity - 1;
    }
    return setValues(item);
  };

  const setCounts = (action) => {
    const counts = { ...countDetails };
    const item = { ...values };

    if (action === "add") {
      if (+data.product.available_quantity > item.quantity) {
        counts.price = +counts.price + +data?.product?.auction_price;
        let s = +counts.totalPrice + +data?.product?.total_sale_amount;
        counts.totalPrice = parseFloat(s.toFixed(3));
      } else {
        // message.warning("test");
      }
    } else if (action === "remove" && item?.quantity > 1) {
      let x = +counts.price - +data?.product?.total_sale_amount;
      counts.price = parseFloat(x.toFixed(3));
      let v = +counts.totalPrice - +data?.product?.total_sale_amount;
      counts.totalPrice = parseFloat(v.toFixed(3));
    }
    return setCountDetails(counts);
  };

  const handleMessage = (batch, user) => {
    let content = "";
    let t = +values?.quantity * +batch.weight;
    let totalWeight = parseFloat(t.toFixed(3));
    if (currentLanguageCode === "en") {
      content = `I would like to have the following product:
      Product number: ${batch.batch_ref_no}
      Product name:${batch?.name}
      Quantity:${totalWeight}
      Total price:${countDetails?.totalPrice}`;
    } else {
      content = `أود الحصول على المنتج التالي:
      رقم المنتج:${batch.batch_ref_no}
      اسم المنتج:${batch.name}
      الكمية:${totalWeight}
      السعر الاجمالي:${countDetails?.totalPrice}`;
    }
    return content;
  };

  // console.log(data.product);
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeModal} alt="close_modal_err" />
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <div className="modal-content-product">
          {/* <Carousel images={filterImages(data?.product?.images)} /> */}
          <Carousel
            images={data?.product?.images}
            isAvailable={data?.product?.status}
            batch_id={data?.product?.transfer_id}
          />
          <div className="model-content">
            <div className="modal-title">
              <span title={data?.product?.name} className="product-title">
                {data?.product?.name}
              </span>

              <span className="product-price">
                {data?.product?.per_kg_amount} {t("filter_nav.r_s")}
              </span>
            </div>
            <div className="descreption-product">
              <span className="product-Weight-detail">
                <span className="description">{t("products.Weight")}</span>
                <span className="product-nbr-by-kilo">
                  {data?.product?.weight} {t("products.kg")}
                </span>
              </span>
              <span className="product-conetent-by-kilo">
                {t("products.per_kilo")}
              </span>
            </div>
            <div className="product-market-detail">
              <span className=" description product-market">
                {t("products.Market")}
              </span>
              <span className="product-Dammam">{data?.product?.market}</span>
            </div>
            {data?.product?.status === "available" ? (
              <div className="product-price-detail">
                <div className="product-price-description">
                  <span className="description product-price-title">
                    {t("products.price")}
                  </span>
                  <span className="product-price">
                    {data?.product?.auction_price}
                    {/* {countDetails?.price} */}
                    {t("products.sar")}
                  </span>
                </div>
                <div className="count-product">
                  <div
                    className="add-product"
                    onClick={() => {
                      setSize("add");
                      setCounts("add");
                    }}
                  >
                    <img src={AddProduct} alt="add_product_err" />
                  </div>
                  <div className="nbr-product">{values?.quantity}</div>
                  <div
                    className="remove-product"
                    onClick={() => {
                      setSize("remove");
                      setCounts("remove");
                    }}
                  >
                    <img src={removeProduct} alt="remove_product_err" />
                  </div>
                </div>
              </div>
            ) : (
              <p className="description-nothave-product">
                {t("products.availability_msg")}
              </p>
            )}
            <div className="product-price-info">
              {data?.product?.status === "soldout" && (
                <div className="product-price-description">
                  <span className="product-price-title">
                    {t("products.price")}
                  </span>
                  <span className="product-price">
                    {data?.product?.auction_price} {t("products.sar")}
                  </span>
                </div>
              )}
              <Collapse product={data?.product} />
              {data?.product?.status === "available" && (
                <>
                  <div className="product-total-price-detail">
                    <span className="product-total-price-title">
                      {t("products.Total_Price")}
                    </span>
                    <span className="product-total-price-ors">
                      {/* {data?.product?.total_sale_amount} */}
                      {countDetails?.totalPrice}
                      {t("products.r_s")}
                    </span>
                  </div>
                  <div className="actions_pop_up">
                    <button className="btn-addcart" onClick={addToCart}>
                      {t("products.Add_to_Cart")}
                    </button>
                    <WhatsAppSender
                      message={handleMessage(data?.product)}
                      // message={{
                      //   username: "test",
                      //   batch_ref_no: data?.product?.batch_ref_no,
                      //   name: data?.product?.name,
                      //   batch_id: data?.product?.batch_id,
                      // }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductModal;
