import React from "react";
import { WhatsAppOutlined } from "@ant-design/icons";
import "./_SendMessage.scss";
import { useTranslation } from "react-i18next";

const WhatsAppSender = ({ message }) => {
  const { t } = useTranslation();
  const handleSendMessage = () => {
    const phoneNumber = "+966503131030"; // Replace with your WhatsApp phone number
    const content = `Batch Ref : ${message.batch_ref_no} br/ Batch Id : ${message?.batch_id} `; // Replace with your desired message

    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url);
  };

  return (
    <button className="send_message" onClick={handleSendMessage}>
      {t("whatsapp")} <WhatsAppOutlined />
    </button>
    // <p className="message_wts">
    //   Trouble? <span>Let's Chat</span> <WhatsAppOutlined />
    // </p>
  );
};

export default WhatsAppSender;
