import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "../../utils/publicAxios";
import axiosInstance from "../../utils/axios";

const initialState = {
  countries: [],
  cities: [],

  error: null,
  loading: "idle",
};

export const getCountries = createAsyncThunk(
  "api/countries",
  async (values) => {
    let data;
    try {
      const response = await axiosInstance.get(`/login/get_country`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getCities = createAsyncThunk("api/cities", async (id) => {
  let data;
  try {
    const response = await axiosInstance.get(
      `/login/get_cities?country_id=${id}`
    );
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getCountries.fulfilled]: (state, action) => {
      const { country } = action.payload;
      state.countries = country;
      state.loading = "idle";
    },
    [getCountries.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getCities.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getCities.fulfilled]: (state, action) => {
      const { city } = action.payload;
      state.cities = city;
      state.loading = "idle";
    },
    [getCities.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const {} = countrySlice.actions;

export default countrySlice.reducer;
