import { Select, Tag } from "antd";
import cookies from "js-cookie";

function InputSelect({
  formik,
  options,
  defaultOptions,
  onChange,
  label,
  field,
  value,
  onDropdownVisibleChange,
  mode,
  onSearch,
}) {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <span className={field.prefix ? "select" : "select without_prefix"}>
      <label htmlFor={label}>{label}</label>
      <Select
        status={
          formik?.errors[field.name] && formik?.touched[field.name]
            ? "error"
            : null
        }
        showSearch
        optionFilterProp="label"
        onSearch={onSearch}
        mode={mode}
        allowClear
        tagRender={Tag}
        showArrow={currentLanguageCode === "en" || !field.prefix}
        onChange={onChange}
        placeholder={field.placeholder}
        options={options}
        defaultValue={defaultOptions}
        value={value}
        onDropdownVisibleChange={onDropdownVisibleChange}
        className={mode != "multiple" && "select_item"}
      ></Select>
      {field.prefix && <img className="icon_prefix" src={field?.prefix}></img>}

      {formik?.errors[field.name] && formik?.touched[field.name] && (
        <p className="error_txt">{formik?.errors[field.name]}</p>
      )}
    </span>
  );
}

export default InputSelect;
