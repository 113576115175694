function filterImages(arrImgs) {
  return arrImgs?.filter((item) => !item?.endsWith(".mp4"));
}

function FileType(img) {
  if (
    img?.endsWith(".mp4") ||
    img?.endsWith(".mov") ||
    img?.endsWith(".webm") ||
    img?.endsWith(".ogg") ||
    img?.endsWith(".avi")
  ) {
    return "mp4";
  } else return "img";
}
export { filterImages, FileType };
