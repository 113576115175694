import { useState } from "react";
import { useTranslation } from "react-i18next";
import detailIcon from "../../../assets/icons/v2.svg";

function Collapse({ product }) {
  const { t } = useTranslation();
  const [detailProduct, setdetailProduct] = useState(false);

  const handleDetailProduct = () => {
    setdetailProduct(!detailProduct);
  };
  return (
    <>
      <div className="product-select-price-details">
        <span
          className="product-select-price-title"
          onClick={() => handleDetailProduct()}
        >
          {t("products.Price_Details")}
          <img
            src={detailIcon}
            alt="detail_icon_err"
            className={
              detailProduct
                ? "icon-detail-product icon-detail-rotate-product"
                : "icon-detail-product"
            }
          />
        </span>
        <span className="product-selected-price">{product?.batch_ref_no}</span>
      </div>
      {detailProduct && (
        <>
          <div className="product-detail-content">
            <span className="name">{t("products.Auction_Price")}</span>
            <span className="pourcentage"></span>
            <span className="price">
              {product?.auction_price} {t("products.r_s")}
            </span>
          </div>
          <div className="product-detail-content">
            <span className="name">{t("products.Platform")}</span>
            <span className="pourcentage">{product?.profit_percentage}%</span>
            <span className="price">
              {product?.profit_amount} {t("products.r_s")}
            </span>
          </div>
          <div className="product-detail-content">
            <span className="name">{t("products.tax")}</span>
            <span className="pourcentage">{product?.vat_percentage}%</span>
            <span className="price">
              {product?.vat_amount} {t("products.r_s")}
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default Collapse;
