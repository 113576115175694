import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cart from "../../assets/icons/shopping.svg";
import cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCount } from "../../data/slices/cart";
import useIsMounted from "../../hooks/useIsMountedRef";
import { getCookieValue } from "../../helpers/setDeviceData";

function Cart() {
  const { user } = useSelector((state) => state.auth);
  const token = localStorage.getItem("haraj_token");
  const device_data = getCookieValue("device_data");
  const uid = JSON.parse(localStorage.getItem("uid"));

  const isMounted = useIsMounted();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { cart_products, count } = useSelector((state) => state.productCart);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isMounted.current) return;
    if (token) {
      dispatch(getCount({ key: "user", value: uid }));
    } else {
      dispatch(
        getCount({
          key: "device_data",
          value: device_data,
        })
      );
    }
  }, [cart_products]);

  return (
    <div className="item_header cart_header" onClick={() => navigate("/cart")}>
      <img src={cart} alt="" /> {t("header.cart")}
      <p
        className={
          currentLanguageCode === "ar"
            ? "number_product a_r"
            : "number_product e_n"
        }
      >
        {count}
      </p>
    </div>
  );
}

export default Cart;
