import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAll } from "../../../../data/slices/filterBatches";
import handleName from "../../../../helpers/handleName";
import CollapseBtn from "../Collapse/Collapse";
import cookies from "js-cookie";

function FilterItem({ items, title, onChange, selectedItems, type }) {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getClassName = (el) => {
    if (type === "is_available") {
      if (selectedItems === "available") {
        return "btn_collapse clicked";
      } else {
        return "btn_collapse";
      }
    }
    if (selectedItems?.includes(el)) {
      return "btn_collapse clicked";
    } else {
      return "btn_collapse";
    }
  };

  const selectAllItems = (items) => {
    if (items.length === selectedItems.length) {
      dispatch(selectAll({ key: type, value: [] }));
    } else {
      const arr = items.map((el) => el.id);
      dispatch(selectAll({ key: type, value: arr }));
    }
  };

  return (
    <div className="product_type_filter">
      <div className="nav_filter">
        <p className="title">{t(`filter_nav.${title}`)}</p>
        <div className="collapse_items">
          <p
            className={
              items?.length === selectedItems?.length
                ? "btn_select_all clicked_all"
                : "btn_select_all"
            }
            onClick={() => selectAllItems(items)}
          >
            {items?.length === selectedItems?.length
              ? t("filter_nav.unselect_all")
              : t("filter_nav.Select_all")}
          </p>
          <span className="point"></span>
          <CollapseBtn
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            text={isOpen ? t("filter_nav.show_less") : t("filter_nav.show_all")}
          />
        </div>
      </div>
      {isOpen && (
        <div className="content">
          <div className="content_collapse">
            {items?.map((el, index) => (
              <button
                className={getClassName(el.id)}
                key={index}
                onClick={() => dispatch(onChange(el.id))}
              >
                {title === "Origin_Market"
                  ? handleName(
                      el?.market_source_name_ar,
                      el?.market_source_name,
                      currentLanguageCode
                    )
                  : handleName(
                      el?.product_type_name_ar,
                      el?.product_type_name,
                      currentLanguageCode
                    ) || el.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterItem;
