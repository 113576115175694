import Demo from "../../../../components/Slider/Slider";
import FilterItem from "../FilterItem/FilterItem";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarkets,
  getProductTypes,
} from "../../../../data/slices/productTypes";
import {
  set_product_type,
  set_market_id,
  set_is_available,
  reset,
} from "../../../../data/slices/filterBatches";
import { getProducts } from "../../../../data/slices/products";
import { cleanObject } from "../../../../helpers/cleanObj";
import useIsMounted from "../../../../hooks/useIsMountedRef";
import cookies from "js-cookie";
import { closeFilter } from "../../../../data/slices/settingsSlice";
import AvFilterItem from "../FilterItem/AvailableFilterItem";

function Filter() {
  const isMounted = useIsMounted();
  const currentLanguageCode = cookies.get("i18next") || "en";

  const filterParams = useSelector((state) => state.filter);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { product_types, markets } = useSelector((state) => state.types);
  const { product_type_id, market_id, is_available, product_category_id } =
    useSelector((state) => state.filter);

  const filterTypes = {
    title: "product_type",
  };

  const filterMarket = {
    title: "Origin_Market",
  };

  const filterAvailability = {
    title: "Availability",
    items: [
      {
        id: 1,
        value: "no",
        label: t("filter_nav.on_demand"),
        type: "on_demand",
      },
      {
        id: 2,
        value: "yes",
        label: t("filter_nav.Available"),
        type: "is_available",
      },
    ],
  };

  const handleQuery = (key, value) => {
    if (value || value?.length > 0) {
      return `?${key}=${value}`;
    } else return "";
  };
  useEffect(() => {
    if (!isMounted.current) return;
    dispatch(getMarkets());
    dispatch(getProductTypes(""));
  }, []);

  useEffect(() => {
    // if (!isMounted.current) return;
    if (product_category_id && product_category_id?.length > 0) {
      dispatch(
        getProductTypes(
          handleQuery("product_category_id", product_category_id[0])
        )
      );
    }
  }, [dispatch, product_category_id]);

  //apply filters with btn
  const applyFilters = () => {
    dispatch(
      getProducts({
        values: cleanObject({ ...filterParams }),
        lang: currentLanguageCode,
      })
    );
    dispatch(closeFilter());
  };

  const resetFilters = () => {
    dispatch(
      getProducts({
        values: cleanObject({}),
        lang: currentLanguageCode,
      })
    );
    dispatch(closeFilter());
  };

  return (
    <div className="filter">
      <div className="slider_filter">
        <p className="filter_text">{t("filter_nav.price")}</p>
        <Demo />
      </div>
      <div className="divider"></div>
      <FilterItem
        items={product_types}
        title={filterTypes.title}
        onChange={set_product_type}
        selectedItems={product_type_id}
        type="product_type_id"
      />
      <div className="divider"></div>
      <FilterItem
        items={markets}
        title={filterMarket.title}
        onChange={set_market_id}
        selectedItems={market_id}
        type="market_id"
      />
      <div className="divider"></div>
      <AvFilterItem
        items={filterAvailability.items}
        title={filterAvailability.title}
        type="is_available"
        selectedItems={is_available}
      />
      {width < 1089 && (
        <div className="action_filter">
          <button className="btn_1" onClick={applyFilters}>
            {t("filter_nav.apply_filter")}
          </button>
          <button
            className="btn_2"
            onClick={() => {
              dispatch(reset());
              resetFilters();
            }}
          >
            {t("filter_nav.cancel")}
          </button>
        </div>
      )}
    </div>
  );
}

export default Filter;
